import * as React from 'react';
import { Button, makeStyles, Theme, Typography } from '@material-ui/core';
import nl2br from 'nl2br';
import {
  ChoiceButtonOnClickProps,
  ChoiceButtonProps,
  SingleChoiceProps,
} from './ElementTypeInterfaces';

const useStyles = makeStyles((theme: Theme) => ({
  squareButton: {
    borderRadius: 0,
    margin: `${theme.spacing()}px 0`,
    textTransform: 'none',
  },
  root: {
    width: '100%',
    maxWidth: 800,
  },
}));

const ChoiceButton: React.FunctionComponent<
  ChoiceButtonOnClickProps & ChoiceButtonProps
> = (props) => {
  const classes = useStyles();

  const onChange = () => {
    props.onChange(props.choice.value);
  };

  return (
    <Button
      data-test-id={`ChoiceButton ${props.choice.value}`}
      fullWidth
      key={props.choice.value}
      onClick={onChange}
      variant="contained"
      color={props.selected ? 'primary' : 'default'}
      className={classes.squareButton}>
      <span dangerouslySetInnerHTML={{ __html: nl2br(props.choice.text) }} />
    </Button>
  );
};

const SingleChoice: React.FunctionComponent<SingleChoiceProps> = ({
  choices,
  onChange,
  value,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {choices.map((choice) => (
        <ChoiceButton
          choice={choice}
          selected={choice.value === value}
          key={choice.value}
          onChange={onChange}
        />
      ))}
    </div>
  );
};

export default SingleChoice;
