import {
  makeStyles,
  MuiThemeProvider,
  Theme,
  useTheme,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import {
  all,
  allPass,
  complement,
  has,
  lte,
  path,
  pathEq,
  pathOr,
  pipe,
  propEq,
  propOr,
  propSatisfies,
  startsWith,
} from 'ramda';
import { isNotEmpty } from 'ramda-adjunct';
import * as React from 'react';
import { isPageWithId } from 'xperience-model-management';
import { onNextClick, onPreviousClick } from '../surveyCollector/screenStreams';
import {
  SurveyMetadata,
  SurveyProgressData,
} from '../surveyCollector/SurveyCollector';
import LanguageSelect from './components/LanguageSelect';
import SurveyNavigation from './components/SurveyNavigation';
import SurveyProgress from './components/SurveyProgress';
import { reloadWithLanguage } from './reloadWithLanguage';
import { State, StateType } from './states';
import { UIState } from './UIState';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      height: '100%',
    },
    maxHeight: '100%',
    margin: `0 ${theme.spacing(3)}px`,

    [theme.breakpoints.down('xs')]: {
      margin: `0 ${theme.spacing() / 2}px`,
      height: '100%',
    },
  },
  gridLogo: {
    margin: theme.spacing(),
    marginLeft: 0,
  },
  logo: {
    float: 'right',
    maxHeight: '65px',
  },
  progress: {
    marginBottom: theme.spacing(),
  },
  screen: {
    minHeight: 400,
    overflowY: 'auto',
    overflowX: 'hidden',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      minHeight: 0,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
    flexWrap: 'nowrap',
  },
  pageTitle: {
    backgroundColor: theme.palette.common.white,
    maxWidth: 960,
    width: '100%',
    borderWidth: 960,
    marginLeft: 'auto',
    marginRight: 'auto',
    borderBottom: `${theme.spacing() / 2}px solid ${
      theme.palette.primary.main
    }`,
    paddingBottom: theme.spacing(),
    marginBottom: theme.spacing(),
    fontSize: '1.3rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.1rem',
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
  },
}));

type LayoutProps = {
  state: State;
};

const isQuestion = propSatisfies(startsWith('question'), 'type');
const isNotQuestion = complement(isQuestion);
const isStandaloneScreen: (state: State) => Boolean = pipe(
  pathOr([], ['screen', 'elements']),
  allPass([isNotEmpty, all(isNotQuestion)])
);
const isIntro = pathEq(['screen', 'elements', 0, 'type'], 'intro');
const isFillInBlocked = propEq('type', StateType.FillInBlocked);
const isConnectionError = propEq('type', StateType.ConnectionErrorDisplayed);
const isLoading = propEq('type', StateType.Loading);

const getGoBackDisabled = pathOr(false, ['screen', 'status', 'disableGoBack']);
const getProgress = pathOr<SurveyProgressData>({ total: 1, current: 0 }, [
  'screen',
  'progress',
]);

const getSurveyMetadata: (obj) => SurveyMetadata = propOr(
  {
    availableLanguages: [],
    surveyLanguage: 'en_US',
    directionality: 'ltr',
  },
  'surveyMetadata'
);
const hasOtherLanguages = pipe(
  pathOr(0, ['surveyMetadata', 'availableLanguages', 'length']),
  lte(2)
);

const changeFavicon = (href: string) => {
  const favicon = document.getElementById('favicon') as HTMLLinkElement;
  favicon.href = href;
};
const changeTitle = (titleText: string) => {
  const title = document.getElementById('title');
  if (title) {
    title.textContent = titleText;
  }
};

const getPageTitle = (state: State): string | boolean => {
  const rawScreenElement = path(['screen', 'rawScreenElement'], state);
  if (
    rawScreenElement &&
    isPageWithId(rawScreenElement) &&
    has('title', rawScreenElement)
  ) {
    return pathOr(false, ['title'], rawScreenElement);
  }

  return false;
};

const Layout: React.FunctionComponent<LayoutProps> = ({ state }) => {
  const theme = useTheme();
  const classes = useStyles();

  const hideProcessElements =
    isStandaloneScreen(state) ||
    isFillInBlocked(state) ||
    isConnectionError(state);
  const showLanguageSwitch = path(
    ['surveyMetadata', 'surveySettings', 'showLanguageSwitch'],
    state
  );
  const showLanguageSelect =
    (showLanguageSwitch === 'allItems' || isIntro(state)) &&
    hasOtherLanguages(state);

  const goBackDisabled = getGoBackDisabled(state);
  const progress = getProgress(state);
  const metadata = getSurveyMetadata(state);
  // @ts-ignore
  const faviconPath = theme.images.favicon;
  // @ts-ignore
  const titleText = theme.title;

  const pageTitle = getPageTitle(state);

  React.useEffect(() => {
    changeFavicon(faviconPath);
    changeTitle(titleText);
  }, [faviconPath, titleText]);
  return (
    <MuiThemeProvider
      theme={{
        ...theme,
        direction: metadata.directionality,
      }}>
      {!isLoading(state) && (
        <Grid
          container
          direction="column"
          wrap="nowrap"
          className={classes.root}>
          <div className={classes.content}>
            <Grid item>
              <Grid container className={classes.header}>
                <Grid item className={classes.gridLogo}>
                  <img
                    // @ts-ignore
                    src={theme.images.logo}
                    className={classes.logo}
                  />
                </Grid>
                {showLanguageSelect && (
                  <Grid item>
                    <LanguageSelect
                      currentLanguage={metadata.surveyLanguage}
                      languages={metadata.availableLanguages}
                      onChange={reloadWithLanguage}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            {!hideProcessElements && (
              <Grid item className={classes.progress}>
                <SurveyProgress
                  totalCount={progress.total}
                  completed={progress.current}
                  // @ts-ignore
                  image={theme.images.progressImagePath}
                  showValue
                />
              </Grid>
            )}
            {pageTitle && (
              <Grid item className={classes.pageTitle}>
                {pageTitle}
              </Grid>
            )}

            <Grid item className={classes.screen} id="screenQuestion">
              <UIState />
            </Grid>

            {!hideProcessElements && (
              <Grid item>
                <SurveyNavigation
                  goBackDisabled={goBackDisabled}
                  onNextClick={onNextClick}
                  onPreviousClick={onPreviousClick}
                />
              </Grid>
            )}
          </div>
        </Grid>
      )}
    </MuiThemeProvider>
  );
};

export default Layout;
