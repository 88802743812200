import { makeStyles, Theme, Typography } from '@material-ui/core';
import useInterval from '@use-it/interval';
import nl2br from 'nl2br';
import * as React from 'react';
import { OutroProps } from './ElementTypeInterfaces';

const useStyles = makeStyles((theme: Theme) => ({
  textBorder: {
    borderColor: theme.palette.primary.main,
    border: '3px solid',
    margin: theme.spacing(),
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.3125rem',
    },
  },
}));

const Outro: React.FunctionComponent<OutroProps> = ({
  text,
  redirect,
  redirectLink = '',
  redirectSeconds,
  isRedirect = false,
}) => {
  const [seconds, setSeconds] = React.useState(redirectSeconds as number);
  const showRedirectBlock = isRedirect;
  const classes = useStyles();

  useInterval(
    () => {
      setSeconds((currentCount) => currentCount - 1);
    },
    showRedirectBlock ? 1000 : null
  );

  if (showRedirectBlock && seconds < 1) {
    window.location.href = redirectLink;
    return null;
  }

  let outroTextParts: React.ReactNode[] = [];
  if (showRedirectBlock) {
    const EXPRESSION_LINK = /(\$\{\ *linkText\ *\})/;
    const EXPRESSION_SECONDS = /(\$\{\ *seconds\ *\})/;
    const Link = (
      <a style={{ textDecoration: 'none' }} href={redirectLink} key="link">
        {redirect!.linkText}
      </a>
    );

    const outroTextSeconds = redirect!.text.replace(
      EXPRESSION_SECONDS,
      `${seconds}`
    );
    outroTextParts = outroTextSeconds.split(EXPRESSION_LINK);
    outroTextParts[1] = Link;
  }

  return (
    <div>
      <Typography
        color="primary"
        className={classes.textBorder}
        variant="h3"
        dangerouslySetInnerHTML={{ __html: nl2br(text) }}
      />
      {showRedirectBlock && (
        <>
          <div style={{ marginTop: '20px' }}>{outroTextParts}</div>
        </>
      )}
    </div>
  );
};

export default Outro;
