import * as React from 'react';
import { makeStyles, TextField, Theme } from '@material-ui/core';
import { QuestionTextProps } from './ElementTypeInterfaces';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    maxWidth: '1000px',
  },
  inputLabel: {
    paddingRight: 24,
    position: 'relative',
    textAlign: 'left',
    paddingBottom: theme.spacing(),
    '&[data-shrink="true"]': {
      transform: 'translate(12px, 10px)',
    },
  },
}));

const QuestionText: React.FunctionComponent<QuestionTextProps> = (props) => {
  const {
    autofocus,
    error = false,
    onBlur,
    onChange,
    title,
    value,
    rows = 4,
  } = props;
  const classes = useStyles();
  const elementRef = React.useRef<HTMLInputElement>(null);

  const onBlurInternal = () => {
    onBlur();
  };
  const onChangeInternal = (event) => {
    onChange(event.target.value);
  };

  React.useEffect(() => {
    if (autofocus && elementRef.current) {
      elementRef.current.focus();
    }
  }, []);

  return (
    <div className={classes.root}>
      <TextField
        inputProps={{
          maxLength: 3000,
        }}
        data-test-id="QuestionText"
        data-textinput-scroll-point
        InputLabelProps={{
          className: classes.inputLabel,
        }}
        style={{
          backgroundColor: '#e8e8e8',
        }}
        InputProps={{
          style: {
            backgroundColor: 'inherit',
            paddingTop: 8,
          },
        }}
        inputRef={elementRef}
        value={value}
        label={title}
        rows={rows}
        multiline
        fullWidth
        variant="filled"
        margin="normal"
        onChange={onChangeInternal}
        onBlur={onBlurInternal}
        error={error}
        autoFocus={autofocus}
      />
    </div>
  );
};

export default QuestionText;
