import {
  GenderProps,
  IntroProps,
  MultiChoiceProps,
  OutroProps,
  QuestionTextProps,
  SingleChoiceProps,
  SquareRatingProps,
  StarRatingProps,
} from '../elementTypes/ElementTypeInterfaces';
import { startsWith } from 'ramda';
import ScreenElement, {
  QUESTION_VISUAL_TYPE,
} from '../uiState/components/ScreenElement';
import { ExpressionFunction } from './expressionEvaluation';
import { operationMapping } from './placeholderProcessing';
import { Direction } from '@material-ui/core/styles';

export type Config = {
  REACT_APP_API_URI: string;
  REACT_APP_PREVIEW_MODE_REGEX_URI?: string;
  REACT_APP_PREVIEW_MODE: string;
};

export type SurveyParams = {
  surveyId: string;
  surveyLanguage?: string;
};

export type LoadingErrorResponse = {
  status: number;
  message: string;
};

export type LoadingFinishedResponse = {
  surveyStatus: 'FINISHED' | 'EXPIRED' | 'OPT_OUT';
  userMessage: string;
};

export type Survey = {
  definition: [];
  translations: { [key: string]: { text: string; [key: string]: any } };
  metadata: { [key: string]: any };
};

export type LoadingStandardResponse = {
  surveyStatus: 'NEW' | 'IN_PROGRESS';
  sampleData: Values;
  collectedData: Values;
  survey: Survey;
  events: QuestionEvent[];
  surveyLanguage: string;
  availableLanguages: string[];
  brand: Brand;
};

export type SurveyData = {
  surveyStatus: 'IN_PROGRESS' | 'FINISHED';
  collectedData: Values;
  events: QuestionEvent[];
  surveyLanguage: string;
};

export type SurveyMetadata = {
  surveyLanguage: string;
  availableLanguages: string[];
  directionality: Direction;
  version: number;
  variant: string; // TODO enum?
  autoNextEnabled?: boolean;
  genderImageMapping: string;
};

export type LoadingResponse = LoadingFinishedResponse | LoadingStandardResponse;

export type TextExpressionDefinitionVariable = {
  type: 'variable';
  value: any;
};
export type TextExpressionDefinitionOperation = {
  type: 'function';
  operation: keyof typeof operationMapping;
  args: TextExpression[];
};

export type TextExpressionElement =
  | string
  | TextExpressionDefinitionVariable
  | TextExpressionDefinitionOperation;

export type TextExpression = TextExpressionElement | TextExpressionElement[];

export interface Choices {
  value: string | number;
  text: string;
}

export interface Question {
  id: string;
  type: string;
  text: string;
  optional?: boolean;
  choices?: Choices[];
  componentProps?: object;
}

export interface QuestionGroup {
  id?: string;
  type: string;
  elements?: QuestionnaireElement[];
}

export type QuestionnaireElement = Question | QuestionGroup;

export type Questionnaire = QuestionnaireElement[];

export type SurveyScreenPathElements = number | string;
export type SurveyScreenPath = SurveyScreenPathElements[];

export type Values = { [key: string]: any };

export type RequestedActions =
  | {
      next: boolean;
    }
  | {
      previous: boolean;
    };

export interface SurveyScreenDefinition {
  id: number;
  rawElements: ScreenElement[];
  elements: ScreenElement[];
  values: Values;
  nextRequested?: boolean;
  processCollectedData?: boolean;
  rawScreenElement?: ScreenElement;
}

export interface SurveyProgressData {
  total: number;
  current: number;
}

export interface SurveyScreen extends SurveyScreenDefinition {
  elements: ScreenElementInstance[];
  status: {
    valid: boolean;
    complete: boolean;
    finished: boolean;
    displayValidationErrors?: boolean;
    disableGoBack?: boolean;
  };
  progress?: SurveyProgressData;
  requestedActions?: RequestedActions;
}

export type QuestionType =
  | 'questionSingleChoice'
  | 'questionMultiChoice'
  | 'questionStarRanking'
  | 'questionText'
  | 'questionStarRating'
  | 'questionGender'
  | 'questionSquareRating';
export type ComponentType =
  | 'intro'
  | 'outro'
  | 'escape'
  | 'personalAgreement'
  | QuestionType;

export const isTypeQuestion = startsWith('question');

export type ValidationResult = {
  message: string;
};

type ElementComponentProps =
  | Partial<IntroProps>
  | Partial<SingleChoiceProps>
  | Partial<MultiChoiceProps>
  | Partial<QuestionTextProps>
  | Partial<StarRatingProps>
  | Partial<GenderProps>
  | Partial<SquareRatingProps>
  | Partial<OutroProps>;

export interface ScreenElement {
  id: string;
  type: ComponentType;
  text?: TextExpression;
  componentProps?: ElementComponentProps;
  optional?: boolean;
  visibleIf?: ExpressionFunction;
  hideIf?: ExpressionFunction;
  optOut?: {}; // TODO split intro definition?
  agreement?: {};
  questionVisualType?: QUESTION_VISUAL_TYPE;
  elements?: ScreenElement[];
  choices?: Choices[];
}

export interface EnrichedScreenElement {
  preHooks: any[];
  postHooks: any[];
  status: {
    visible: boolean;
    touched: boolean;
    validationResult: ValidationResult[];
    disableGoBack?: boolean;
  };
  value?: any;
}

export type ScreenElementInstance = ScreenElement & EnrichedScreenElement;

export interface SectionJourney {
  enter: SurveyScreenPath[];
  exit: SurveyScreenPath[];
}

export type MoveSource =
  | 'offlineRecovery'
  | 'browserNavigation'
  | 'autoNext'
  | 'surveyNavigation'
  | 'skipEmptyScreen'
  | 'escapeQuestion';

type ScreenMoveBasic = {
  moveDirection: 'next' | 'previous' | 'none' | 'end';
  source: MoveSource;
  sendData?: boolean;
};

type ScreenMoveJump = {
  moveDirection: 'jump';
  jumpPath: SurveyScreenPath;
  source: MoveSource;
  sendData?: boolean;
};

export type ScreenMove = ScreenMoveJump | ScreenMoveBasic;

export type DataCollectionType = 'collectedData' | 'temporaryData';

export interface SurveyProgress {
  total: number;
  current: number;
}

export type QuestionEvent = {
  eventType: 'QUESTION_HIDDEN' | 'QUESTION_DISPLAYED';
  questionId: string;
  timestamp: string;
};

export enum BrandsEnum {
  SEAT = 'S',
  AUDI = 'A',
  SKODA = 'C',
  NEVQS = 'N',
  PORSCHE = 'P',
  VW = 'V',
  PASSENGERVW = 'W',
  CUPRA = 'U',
  DEFAULT = '',
}

export type Brand = {
  code: string;
  name?: string;
};
