import { blueGrey, grey } from '@material-ui/core/colors';
import { createMuiTheme, Theme as MUITheme } from '@material-ui/core';
import personalAgreementBg from '../static/images/skoda.png';

const fontFamily =
  '"SKODA Next", "Helvetica Neue", Helvetica, Arial, sans-serif';

const { spacing } = createMuiTheme();

const theme = {
  palette: {
    primary: blueGrey,
    secondary: grey,
    background: {
      default: 'ffffff',
    },
  },
  typography: {
    fontFamily: fontFamily,
    useNextVariants: true,
  },
  screenElement: {
    sidePadding: spacing(3),
    sidePaddingXs: spacing(),
  },
  images: {
    introDefaultImagePath: '',
    progressImagePath: '',
    logo: '',
  },
  personalAgreement: {
    backgroundImagePath: personalAgreementBg,
    continueButtonColor: '#3b9cdf',
  },
  title: 'Survey',
  brandCode: undefined,
};

export type Theme = typeof theme & MUITheme;

export default theme;
