import { makeStyles, Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { Button, Divider, Icon } from '@material-ui/core';
import { Trans } from 'react-i18next';
import classNames from 'class-names';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      padding: `0 ${theme.spacing() / 2}px`,
    },
    [theme.breakpoints.down('md')]: {
      padding: `0 ${theme.spacing(3)}px`,
      position: 'absolute',
      bottom: 0,
      right: 0,
      width: '100%',
    },
    zIndex: 10,
  },
  squareButton: {
    float: 'right',
    borderRadius: 0,
    boxShadow: 'none',
    textTransform: 'none',
  },
  backButton: {
    backgroundColor: '#ffffff',
  },
  divider: {
    float: 'left',
    width: '100%',
    height: 4,
    backgroundColor: theme.palette.primary.main,
  },
  icon: {
    transform: `scaleX(${theme.direction === 'rtl' ? -1 : 1})`,
  },
}));

interface NavigationProps {
  onNextClick: () => void;
  onPreviousClick: () => void;
  goBackDisabled: boolean;
}

const SurveyNavigation: React.FunctionComponent<NavigationProps> = ({
  onNextClick,
  onPreviousClick,
  goBackDisabled = false,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Button
        data-test-id="goForwardBtn"
        onClick={onNextClick}
        variant="contained"
        color="primary"
        className={classes.squareButton}>
        <Icon className={classes.icon}>chevron_right</Icon>
        <Trans i18nKey="ui.surveyNavigation.goForward">Go forward</Trans>
      </Button>
      <Button
        disabled={goBackDisabled}
        data-test-id="goBackBtn"
        onClick={onPreviousClick}
        className={classNames(classes.squareButton, classes.backButton)}>
        <Trans i18nKey="ui.surveyNavigation.goBack">Go back</Trans>
        <Icon className={classes.icon}>chevron_left</Icon>
      </Button>
      <Divider className={classes.divider} />
    </div>
  );
};

export default SurveyNavigation;
