import * as React from 'react';
import { always, cond, equals, inc, range } from 'ramda';
import IconButton from '@material-ui/core/IconButton/IconButton';
import StarIcon from '@material-ui/icons/Star';
import StarOutlinedIcon from '@material-ui/icons/StarBorder';
import nl2br from 'nl2br';
import { Choice, RatingProps } from './ElementTypeInterfaces';
import {
  Button,
  Grid,
  makeStyles,
  useTheme,
  Theme,
  Typography,
} from '@material-ui/core';
import classNames from 'class-names';
import { Trans } from 'react-i18next';

// @ts-ignore - unconventional IE detection
const isIE = Boolean(window.document.documentMode);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  choices: {
    marginTop: 10,
  },
  choice: {
    margin: `${theme.spacing()}px 0`,
    textTransform: 'none',
  },
  choiceSelected: {
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: '#4ba82e20',
  },
  squareIconComponent: {
    borderRadius: 0,
    height: 36,
  },
  iconComponent: {
    minWidth: 1,
    padding: 0,
  },
  squareItem: {
    minWidth: 1,
    cursor: 'pointer',
    padding: `0 ${theme.spacing() / 2}px`,
  },
  starIconComponent: {
    maxWidth: 100,
    maxHeight: 100,
    width: '100%',
    height: '100%',
    fontSize: '100%',
  },
  labelInfo: {
    margin: `${theme.spacing()}px ${theme.spacing() / 2}px`,
    width: 'auto',
  },
  labelMin: {
    maxWidth: '40%',
    textAlign: 'left',
  },
  labelMax: {
    maxWidth: '40%',
    textAlign: 'right',
  },
}));
const useMouseEvent = ({
  position,
  setPosition,
  restoreValues,
  onClick,
}: HookProps) => {
  const [isTouch, setTouch] = React.useState(false);

  const onMouseEnter = () => {
    if (!isTouch) {
      setPosition(position);
    }
  };
  const onMouseLeave = () => {
    if (!isTouch) {
      restoreValues();
    }
  };
  const onTouchStart = () => {
    setTouch(true);
  };
  const onClickHandler = () => {
    setPosition(position);
    onClick(position);
  };
  return [onMouseEnter, onMouseLeave, onTouchStart, onClickHandler];
};

const SquareElement: React.FunctionComponent<ElementProps> = React.memo(
  ({ filled, onClick, setPosition, restoreValues, position }) => {
    const classes = useStyles();
    const [
      onMouseEnter,
      onMouseLeave,
      onTouchStart,
      onClickHandler,
    ] = useMouseEvent({ position, setPosition, restoreValues, onClick });

    return (
      <Grid
        item
        xs
        className={classes.squareItem}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClickHandler}
        onTouchStart={onTouchStart}>
        <Button
          data-test-id={`SquareElement ${position}`}
          fullWidth
          variant="contained"
          color={filled ? 'primary' : 'default'}
          className={classNames(
            classes.squareIconComponent,
            classes.iconComponent
          )}>
          {position}
        </Button>
      </Grid>
    );
  }
);

const StarElement: React.FunctionComponent<ElementProps> = React.memo(
  ({
    filled,
    onClick,
    setPosition,
    restoreValues,
    position,
    starWidthForIE,
  }) => {
    const IconComponent = filled ? StarIcon : StarOutlinedIcon;
    const classes = useStyles();

    const [
      onMouseEnter,
      onMouseLeave,
      onTouchStart,
      onClickHandler,
    ] = useMouseEvent({ position, setPosition, restoreValues, onClick });

    return (
      <Grid
        item
        xs
        className={classes.squareItem}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClickHandler}
        onTouchStart={onTouchStart}
        style={{
          flexBasis: isIE ? 'auto' : undefined,
        }}>
        <IconButton
          data-test-id={`StarElement ${position}`}
          color="primary"
          className={classes.iconComponent}>
          <IconComponent
            className={classes.starIconComponent}
            style={{
              width: starWidthForIE,
              height: starWidthForIE,
            }}
          />
        </IconButton>
      </Grid>
    );
  }
);

export const ChoiceButton: React.FC<ButtonProps> = (props) => {
  const classes = useStyles();

  const onClickHandler = () => {
    props.onChange(props.choice.value);
    props.setPosition(props.choice.value);
  };

  return (
    <Button
      data-test-id={`ChoiceButton ${props.choice.value}`}
      key={props.choice.value}
      onClick={onClickHandler}
      variant="outlined"
      className={classNames(classes.choice, {
        [classes.choiceSelected]: props.selected,
      })}>
      <span dangerouslySetInnerHTML={{ __html: nl2br(props.choice.text) }} />
    </Button>
  );
};

const getElementByVariant = cond([
  [equals('star'), always(StarElement)],
  [equals('square'), always(SquareElement)],
]) as (variant: 'star' | 'square') => typeof StarElement | typeof SquareElement;

export const Rating: React.FunctionComponent<RatingProps> = React.memo(
  (props) => {
    const {
      count = 5,
      choices,
      onChange,
      startFromZero = false,
      variant,
      labelMin,
      labelMax,
    } = props;

    const theme = useTheme();
    const classes = useStyles();

    const [currentPosition, setCurrentPosition] = React.useState<
      number | undefined
    >(props.value);

    const restoreValues = () => {
      setCurrentPosition(props.value);
    };
    const setPosition = (value?: number) => {
      setCurrentPosition(value);
    };

    const starWidthForIE =
      isIE && variant === 'star'
        ? `calc((100vw - ${theme.spacing(18)}px) / ${count})`
        : undefined;

    const calculatePosition = startFromZero ? (item: number) => item : inc;
    const ratingStates = range(0, count).map(
      (index: number) =>
        currentPosition !== undefined &&
        currentPosition >= calculatePosition(index) &&
        currentPosition <= count
    );

    const Element = getElementByVariant(variant);
    const rating = ratingStates.map((filled, i) => {
      return (
        <Element
          onClick={onChange}
          setPosition={setPosition}
          restoreValues={restoreValues}
          position={calculatePosition(i)}
          currentPosition={currentPosition}
          key={i}
          filled={filled}
          starWidthForIE={starWidthForIE}
        />
      );
    });

    return (
      <div className={classNames({ [classes.root]: variant === 'square' })}>
        <Grid container direction="row" justify="center" alignItems="center">
          {rating}
        </Grid>
        <Grid
          className={classes.labelInfo}
          container
          direction="row"
          justify="space-between"
          alignItems="center">
          <Grid item className={classes.labelMin}>
            <Typography variant="caption" color="inherit">
              {labelMin ? (
                labelMin
              ) : (
                <Trans
                  i18nKey={`elements.${
                    variant === 'square' ? 'squareRating' : 'starRating'
                  }.labelMin`}>
                  {variant === 'square' ? 'Most Unlikely' : ' '}
                </Trans>
              )}
            </Typography>
          </Grid>
          <Grid item className={classes.labelMax}>
            <Typography variant="caption" color="inherit">
              {labelMax ? (
                labelMax
              ) : (
                <Trans
                  i18nKey={`elements.${
                    variant === 'square' ? 'squareRating' : 'starRating'
                  }.labelMax`}>
                  {variant === 'square' ? 'Most Likely' : ' '}
                </Trans>
              )}
            </Typography>
          </Grid>
        </Grid>
        {choices ? (
          <Grid
            className={classNames({ [classes.choices]: variant === 'square' })}
            container
            direction="column"
            justify="center"
            alignItems="center">
            {choices.map((choice) => (
              <ChoiceButton
                key={choice.value}
                selected={currentPosition === choice.value}
                choice={choice}
                onChange={onChange}
                setPosition={setPosition}
              />
            ))}
          </Grid>
        ) : (
          ''
        )}
      </div>
    );
  }
);

interface HookProps {
  position: number;
  setPosition: (value) => void;
  restoreValues: () => void;
  onClick: (value) => void;
}
interface ButtonProps {
  choice: Choice;
  selected: boolean;
  onChange: (value) => void;
  setPosition: (value) => void;
}
interface ElementProps {
  position: number;
  currentPosition: number | undefined;
  onClick: (value) => void;
  restoreValues: () => void;
  setPosition: (value) => void;
  filled: boolean;
  starWidthForIE?: string;
}
