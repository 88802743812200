import * as React from 'react';
import { EscapeQuestionBasicProps } from './ElementTypeInterfaces';
import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import nl2br from 'nl2br';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    thanksTextBox: {
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(3),
    },
    thanksText: {
      color: '#ffffff',
      padding: theme.spacing(3),
    },
    continueTextBox: {
      display: 'flex',
      flexDirection: 'column',
      borderColor: theme.palette.primary.main,
      borderWidth: 3,
      borderStyle: 'solid',
      color: '#ffffff',
      marginTop: theme.spacing(2),
    },
    text: {
      margin: theme.spacing(3),
      flexGrow: 1,
    },
    terminateTextBox: {
      display: 'flex',
      flexDirection: 'column',
      borderColor: '#CCCCCC',
      borderWidth: 3,
      borderStyle: 'solid',
      color: '#ffffff',
      marginTop: theme.spacing(2),
    },
    terminateText: {
      color: '#CCCCCC',
      margin: theme.spacing(3),
    },
    terminateButton: {
      backgroundColor: '#CCCCCC',
      borderRadius: 0,
      margin: 0,
      minWidth: 150,
      boxShadow: 'none',

      '&:hover': {
        backgroundColor: '#BBBBBB',
      },
    },
    saveButton: {
      backgroundColor: '#CCCCCC',
      borderRadius: 0,
      margin: 0,
      minWidth: 250,
      boxShadow: 'none',

      '&:hover': {
        backgroundColor: '#BBBBBB',
      },
    },
    [theme.breakpoints.down('sm')]: {
      buttonContainer: {
        flexDirection: 'column',
      },
      thanksTextBox: {
        padding: theme.spacing(2),
      },
      thanksText: {
        padding: 0,
        fontSize: '1.5rem',
      },
      saveButton: {
        marginBottom: theme.spacing(2),
        minHeight: 48,
      },
      squareButton: {
        minHeight: 48,
      },
      terminateButton: {
        minHeight: 48,
      },
      text: {
        margin: 0,
        padding: theme.spacing(2),
        fontSize: '1.5rem',
        flexGrow: 1,
      },
      terminateText: {
        color: '#CCCCCC',
        margin: 0,
        padding: theme.spacing(2),
        fontSize: '1.5rem',
      },
    },
    squareButton: {
      borderRadius: 0,
      margin: 0,
      minWidth: 150,
      boxShadow: 'none',
    },
    filler: {
      flexGrow: 1,
    },
  })
);

const EscapeQuestionBasic: React.FunctionComponent<EscapeQuestionBasicProps> = (
  props
) => {
  const {
    text,
    thanksMessage,
    onChange,
    continueButtonText = 'Next question »',
    terminateText,
    terminateButtonText = 'Finish',
    saveButtonText = 'Save and continue later',
  } = props;

  const classes = useStyles();
  // text is deprecated, use the continueText
  const continueText = props.continueText || text;

  const onContinueClick = () => onChange(1);
  const onSaveClick = () => onChange(2);
  const onTerminateClick = () => onChange(0);

  return (
    <div className={classes.root}>
      <Grid container justify="center">
        <Grid item xs={12} sm={10} md={8} className={classes.thanksTextBox}>
          <Typography
            className={classes.thanksText}
            variant="h4"
            color="textPrimary"
            align="center"
            dangerouslySetInnerHTML={{ __html: nl2br(thanksMessage) }}
          />
        </Grid>

        <Grid item xs={12} sm={10} md={8} className={classes.continueTextBox}>
          <Typography
            className={classes.text}
            variant="h4"
            color="primary"
            align="center"
            dangerouslySetInnerHTML={{ __html: nl2br(continueText) }}
          />
          <Grid
            container
            justify="flex-end"
            className={classes.buttonContainer}>
            <Button
              data-test-id="continueButton"
              onClick={onContinueClick}
              size="large"
              color="primary"
              className={classes.squareButton}
              variant="contained">
              <Typography variant="h6" color="inherit">
                {continueButtonText}
              </Typography>
            </Button>
          </Grid>
        </Grid>

        {terminateText && (
          <Grid
            item
            xs={12}
            sm={10}
            md={8}
            className={classes.terminateTextBox}>
            <Typography
              className={classes.terminateText}
              variant="h4"
              color="secondary"
              align="center"
              dangerouslySetInnerHTML={{ __html: nl2br(terminateText) }}
            />
            <Grid
              container
              justify="flex-end"
              className={classes.buttonContainer}>
              <Button
                data-test-id="saveButton"
                onClick={onSaveClick}
                size="large"
                color="secondary"
                className={classes.saveButton}
                variant="contained">
                <Typography variant="h6" color="inherit">
                  {saveButtonText}
                </Typography>
              </Button>
              <div className={classes.filler} />
              <Button
                data-test-id="terminateButton"
                onClick={onTerminateClick}
                size="large"
                color="secondary"
                className={classes.terminateButton}
                variant="contained">
                <Typography variant="h6" color="inherit">
                  {terminateButtonText}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default EscapeQuestionBasic;
