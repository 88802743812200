import Intro from './Intro';
import SingleChoice from './SingleChoice';
import MultiChoice from './MultiChoice';
import QuestionText from './QuestionText';
import { StarRating } from './StarRating';
import { SquareRating } from './SquareRating';
import Outro from './Outro';
import EscapeQuestion from './EscapeQuestion';
import PersonalAgreementQuestion from './PersonalAgreementQuestion';
import Gender from './Gender';

export const elementMapping = {
  intro: Intro,
  questionSingleChoice: SingleChoice,
  questionMultiChoice: MultiChoice,
  questionText: QuestionText,
  questionStarRating: StarRating,
  questionSquareRating: SquareRating,
  questionDynamicSquareRating: SquareRating,
  escape: EscapeQuestion,
  outro: Outro,
  questionGender: Gender,
  personalAgreement: PersonalAgreementQuestion,
};

export const generalTypeMapping = {
  intro: 'intro',
  questionSingleChoice: 'singleChoice',
  questionMultiChoice: 'multiChoice',
  questionText: 'open',
  questionStarRating: 'singleChoice',
  questionSquareRating: 'singleChoice',
  escape: 'escape',
  outro: 'outro',
  questionGender: 'singleChoice',
  personalAgreement: 'personalAgreement',
};
