import {
  QuestionEvent,
  Questionnaire,
  ScreenElement,
  ScreenElementInstance,
  ScreenMove,
  SurveyScreen,
  SurveyScreenDefinition,
  SurveyScreenPath,
  Values,
} from './SurveyCollector';
import {
  all,
  allPass,
  always,
  anyPass,
  assoc,
  assocPath,
  cond,
  dissoc,
  equals,
  filter,
  find,
  has,
  hasPath,
  ifElse,
  keys,
  last,
  lensPath,
  lensProp,
  map,
  mergeRight,
  of,
  over,
  path,
  pathEq,
  pathOr,
  pick,
  pipe,
  prop,
  propEq,
  propOr,
  reduce,
  view,
  when,
} from 'ramda';
import { createPostHooks, createPreHooks } from './elementHooks';
import {
  goToFinalScreenDisplayed,
  goToLoading,
  goToScreenDisplayed,
  goToScreenProcessing,
} from '../uiState/states';
import {
  getEndScreenPath,
  getNextScreenPath,
  getPreviousScreenPath,
} from './screenPath';
import {
  imagePathExpressionToFunction,
  processOptOut,
  textExpressionToFunction,
} from './placeholderProcessing';
import { expressionToFunction } from './expressionEvaluation';

const getId = prop('id');
const elementsLens = lensProp('elements');
const valuesLens = lensProp('values');

const statusVisibleLens = lensPath(['status', 'visible']);

const copyPropsToComponentProps = (element) => {
  const elementHas = (prop) => has(prop, element);

  // @ts-ignore
  const namesOfProps = filter(elementHas, [
    'choices',
    // intro
    'agreement',
    'startButtonText',
    // escape question
    'thanksMessage',
    'terminateText',
    'terminateButtonText',
    'continueText',
    'continueButtonText',
    'saveDialogText',
    'saveButtonText',
  ]);

  const extendComponentProps = (acc, item) =>
    assocPath(['componentProps', item], acc[item], acc);
  return reduce(extendComponentProps, element, namesOfProps);
};

const copyPlaceholderToComponentTitle = (element) => {
  return assocPath(['componentProps', 'title'], element.placeholder, element);
};

const copyLabelMinToComponentProps = (element) =>
  assocPath(['componentProps', 'labelMin'], element.labelMin, element);

const copyLabelMaxToComponentProps = (element) => {
  return assocPath(['componentProps', 'labelMax'], element.labelMax, element);
};

const copyRedirectItemsToProps = (element) => {
  return assocPath(['componentProps', 'redirect'], element.redirect, element);
};

const evaluateConditionalTexts = (values) => (element) => {
  // @ts-ignore
  const visibleText = find(({ condition }) => {
    const visibleIfHook = expressionToFunction(condition);
    return visibleIfHook(values) as boolean;
  }, element.conditionalTexts);

  const { text } = visibleText || element;

  return assoc('text', text, element);
};

const preparePropsForSecondaryElement = (e) =>
  pipe(
    assocPath(['componentProps', 'title'], e.text),
    dissoc('text'),
    over(
      lensPath(['componentProps', 'autofocus']),
      (autofocus) => autofocus !== false
    )
  )(e);

const elementPreprocessingFactory = (values: Values, variables: Values) =>
  pipe(
    copyPropsToComponentProps,
    // @ts-ignore
    ifElse<ScreenElement, ScreenElement>(
      // @ts-ignore
      propEq('questionVisualType', 'secondary'),
      preparePropsForSecondaryElement,
      when(has('placeholder'), copyPlaceholderToComponentTitle)
    ),
    when<ScreenElement, ScreenElement>(has('optOut'), processOptOut(variables)),
    when<ScreenElement, ScreenElement>(
      has('imagePath'),
      over(lensProp('imagePath'), imagePathExpressionToFunction)
    ),
    when<ScreenElement, ScreenElement>(
      has('text'),
      over(lensProp('text'), textExpressionToFunction)
    ),
    when<ScreenElement, ScreenElement>(
      hasPath(['agreement', 'instruction']),
      over(lensPath(['agreement', 'instruction']), textExpressionToFunction)
    ),
    when<ScreenElement, ScreenElement>(
      has('introduction'),
      over(lensProp('introduction'), textExpressionToFunction)
    ),
    when<ScreenElement, ScreenElement>(
      has('labelMin'),
      copyLabelMinToComponentProps
    ),
    when<ScreenElement, ScreenElement>(
      has('labelMax'),
      copyLabelMaxToComponentProps
    ),
    when<ScreenElement, ScreenElement>(
      has('redirect'),
      copyRedirectItemsToProps
    ),
    // @ts-expect-error - pipe has (bad TYPES): for max 10 functions
    when<ScreenElement, ScreenElement>(
      has('conditionalTexts'),
      evaluateConditionalTexts(mergeRight(variables, values))
    ),
    when<ScreenElement, ScreenElement>(
      has('choices'),
      over(
        lensProp('choices'),
        map(over(lensProp('text'), textExpressionToFunction))
      )
    )
  );

export const createScreenDefinition = (
  elementDefinitions: ScreenElement[],
  values = {},
  variables = {},
  screenElement?: ScreenElement
): SurveyScreenDefinition => {
  const elements = map(
    elementPreprocessingFactory(values, variables),
    elementDefinitions
  );

  return {
    id: Date.now(),
    rawElements: elements,
    elements,
    values: pickValuesForElements(elementDefinitions, values),
    rawScreenElement: screenElement,
  };
};

// @ts-ignore
const isLastEventOnEscapeQuestion = pipe(last, propEq('questionId', 'QE')) as (
  events: QuestionEvent[]
) => Boolean;

export const applyElementsPreHooks = ([screen, variables, events]: [
  SurveyScreenDefinition,
  Values,
  QuestionEvent[]
]): SurveyScreen => {
  // we want to show QE if it was last displayed item
  if (
    screen.processCollectedData &&
    screen.values['QE'] &&
    isLastEventOnEscapeQuestion(events)
  ) {
    screen.values = dissoc('QE', screen.values);
  }

  const values = mergeRight(variables, screen.values);

  return over(
    elementsLens,
    (elements = []) =>
      map((element: ScreenElementInstance) => {
        return reduce<Function, ScreenElementInstance>(
          (
            e: ScreenElementInstance,
            preHook: Function
          ): ScreenElementInstance => preHook(e, values),
          element,
          element.preHooks
        );
      }, elements),
    screen
  ) as SurveyScreen;
};

export const filterInvisibleElements = (screen: SurveyScreen) =>
  over(elementsLens, filter(view(statusVisibleLens)), screen);

export const changeScreenValues = (
  screen: SurveyScreenDefinition,
  newValues: Values
): SurveyScreenDefinition =>
  over(valuesLens, (values) => mergeRight(values, newValues), screen);

export const canGoNext = (screen) => screen.status.valid; // TODO && screen.status.complete;

const hasOnlyOneElement = pathEq(['elements', 'length'], 1);
const firstElementIsIntro = pathEq(['elements', 0, 'type'], 'intro');
const firstElementIsSingleChoice = pathEq(
  ['elements', 0, 'type'],
  'questionSingleChoice'
);
const firstElementIsStarRating = pathEq(
  ['elements', 0, 'type'],
  'questionStarRating'
);
const firstElementIsSquareRating = pathEq(
  ['elements', 0, 'type'],
  'questionSquareRating'
);
export const firstElementIsEscapeQuestion = pathEq(
  ['elements', 0, 'type'],
  'escape'
);
const firstElementIsPersonalAgreementQuestion = pathEq(
  ['elements', 0, 'type'],
  'personalAgreement'
);
const ifIsFirstElementMultiChoiceAndIsSelectedESC = (screen) => {
  const element = screen.elements[0];
  if (element.type !== 'questionMultiChoice') {
    return true;
  }
  const id = element.id;
  return (
    element.type === 'questionMultiChoice' &&
    screen.values[id] &&
    screen.values[id].length === 1 &&
    screen.values[id][0] === 999
  );
};
const firstElementIsMultiChoice = pathEq(
  ['elements', 0, 'type'],
  'questionMultiChoice'
);
const firstElementIsGender = pathEq(['elements', 0, 'type'], 'questionGender');
export const firstElementIsQuestionWithoutNextButton = anyPass([
  firstElementIsEscapeQuestion,
  firstElementIsPersonalAgreementQuestion,
]);

/**
 * evaluate if screen change should be decorate by auto-next handler
 * Conditions should contains check static info, type,... not current values
 */
export const shouldUseAutoNextDecorator = anyPass([
  firstElementIsSingleChoice,
  firstElementIsStarRating,
  firstElementIsSquareRating,
  firstElementIsIntro,
  firstElementIsEscapeQuestion,
  firstElementIsGender,
  firstElementIsPersonalAgreementQuestion,
  firstElementIsMultiChoice,
]);

/**
 * filter auto next request by current screen state and values
 */
export const canUseAutoNext = allPass([
  hasOnlyOneElement,
  shouldUseAutoNextDecorator,
  ifIsFirstElementMultiChoiceAndIsSelectedESC,
]);

const getElementIds = (elementDefinition: ScreenElement[]) =>
  map<ScreenElement, string>(getId, elementDefinition);

export const pickValuesForElements = (
  elementDefinition: ScreenElement[],
  values: Values
) => {
  const keys = getElementIds(elementDefinition);

  return pick(keys, values);
};

const createStatus = always({
  visible: true,
  touched: false,
  complete: false,
  validationResult: [],
});

const preparePropsHandlersFor = (
  element: ScreenElement,
  handlerFactories: HandlerFactories
) => {
  const onChangeHandler = handlerFactories.onChangeFor(element.id);
  const propHandlers = {
    onChange: (value) => onChangeHandler(value),
  };

  if (element.type === 'questionText') {
    propHandlers['onBlur'] = handlerFactories.onBlurFor(element.id);
  }

  if (element.type === 'intro') {
    propHandlers['onStart'] = handlerFactories.onStart;
  }

  return propHandlers;
};

type HandlerFactories = {
  onChangeFor: Function;
  onBlurFor: Function;
  onStart: Function;
  onJump: Function;
};

export const extendElementsFactory = (
  handlerFactories: HandlerFactories,
  {
    variables,
  }: {
    variables: Values;
  }
) => {
  const extendElement = (element: ScreenElement): ScreenElementInstance => {
    return {
      ...element,
      componentProps: {
        ...element.componentProps,
        ...preparePropsHandlersFor(element, handlerFactories),
      },
      preHooks: createPreHooks(element, variables),
      postHooks: createPostHooks(element),
      status: createStatus(),
    };
  };

  return map(extendElement);
};

export const getCompleteStatus = (screen: SurveyScreen): Boolean => {
  const elementsIds = map(prop('id'), screen.elements);
  const valueKeys = keys(screen.values);

  return equals(elementsIds, valueKeys);
};

export const getValidStatus = (screen: SurveyScreen): Boolean => {
  return pipe(
    // @ts-ignore
    map(path(['status', 'validationResult', 'length'])),
    all((length) => length === 0)
  )(screen.elements);
};

export const getGoBackStatus = (screen: SurveyScreen) =>
  screen.elements.some((element) => Boolean(element.status.disableGoBack));

export const updateStateInfo = (screen: SurveyScreen) =>
  assoc(
    'status',
    {
      complete: getCompleteStatus(screen),
      valid: getValidStatus(screen),
      displayValidationErrors: screen.nextRequested,
      finish: false,
      disableGoBack: getGoBackStatus(screen),
    },
    screen
  );

export const getSurveyElement = path as (
  surveyPath: SurveyScreenPath,
  questionnaire: Questionnaire
) => ScreenElement;

export const getCurrentScreenElements = (
  screenElement: ScreenElement
): ScreenElement[] => {
  return ifElse(has('elements'), prop('elements'), of)(screenElement);
};

const isTypeOutro = propEq('type', 'outro');
const hasElements = (screen) => screen.elements.length > 0;

export const isFinalScreen = allPass([
  hasElements,
  (screen) => all(isTypeOutro, screen.elements),
]);

export const collectedDataProcessing = propEq('processCollectedData', true);

export const visibleStateToScreenState = (surveyMetadata) => (
  visibleScreen: SurveyScreen
) => {
  cond([
    [collectedDataProcessing, () => goToLoading()],
    [isFinalScreen, () => goToFinalScreenDisplayed(visibleScreen)],
    [Boolean, () => goToScreenDisplayed(visibleScreen, surveyMetadata)],
    [always(true), goToScreenProcessing],
    // @ts-ignore
  ])(visibleScreen);
};

export const moveRequestToPath = (
  questionnaire: Questionnaire,
  currentPath: SurveyScreenPath,
  moveRequest: ScreenMove
) => {
  switch (moveRequest.moveDirection) {
    case 'next':
      return getNextScreenPath(questionnaire, currentPath);

    case 'previous':
      return getPreviousScreenPath(questionnaire, currentPath);

    case 'end':
      return getEndScreenPath(questionnaire, currentPath);

    case 'jump':
      return moveRequest.jumpPath;

    default:
      return currentPath;
  }
};

export const getElementsIds = (screen: SurveyScreen) =>
  pipe<SurveyScreen, ScreenElementInstance[], string[]>(
    prop('elements'),
    map(prop('id'))
  )(screen);

export const getVisibleElementsIds = (screen: SurveyScreen) =>
  pipe<
    SurveyScreen,
    ScreenElementInstance[],
    ScreenElementInstance[],
    string[]
  >(
    prop('elements'),
    filter(pathOr(false, ['status', 'visible'])),
    map(prop('id'))
  )(screen);

export const setNextRequested = assocPath<boolean, SurveyScreenDefinition>(
  ['nextRequested'],
  true
);

export const preserveProcessCollectedDataFlag = (
  oldScreen: SurveyScreenDefinition,
  screen: SurveyScreenDefinition
) => assoc('processCollectedData', oldScreen.processCollectedData, screen);

export const startCollectedDataProcessing = (
  screen: SurveyScreenDefinition
): SurveyScreenDefinition => assoc('processCollectedData', true, screen);

export const stopCollectedDataProcessing = pipe(
  assoc('processCollectedData', false),
  assoc('nextRequested', false)
);

export const mergeValues: (v1: Values, v2: Values) => Values = mergeRight;
