import {grey, brown} from '@material-ui/core/colors';
import {createMuiTheme, Theme as MUITheme} from '@material-ui/core';
import personalAgreementBg from '../static/images/personal-agreement-bg.png';
import {BrandsEnum} from '../surveyCollector/SurveyCollector';

const cupraCopper = '#95572B';
const dessertSand = '#D9CEBD';

const mainColor = {
  ...brown,
  '500': cupraCopper,
  '600': cupraCopper,
  A100: cupraCopper,
  A200: cupraCopper,
  A300: cupraCopper,
  A400: cupraCopper,
};

const {spacing} = createMuiTheme();

const fontFamily = 'Open Sans, Arial, sans-serif';

const theme = {
  palette: {
    primary: mainColor,
    secondary: {
      light: grey.A100,
      main: dessertSand,
      dark: grey.A400,
      contrastText: '#ffffff'
    },
    background: {
      default: '#ffffff',
    },
  },
  typography: {
    fontFamily: fontFamily,
    useNextVariants: true,
  },
  screenElement: {
    sidePadding: spacing(3),
    sidePaddingXs: spacing(),
  },
  images: {
    introDefaultImagePath: 'images/cupra/introCar.jpg',
    progressImagePath: 'images/cupra/car.png',
    logo: 'images/cupra/logo.jfif',
    favicon: 'favicons/cupra.ico',
  },
  personalAgreement: {
    backgroundImagePath: personalAgreementBg,
    continueButtonColor: cupraCopper,
  },
  title: 'Cupra',
  brandCode: BrandsEnum.CUPRA,
};

export type Theme = typeof theme & MUITheme;

export default theme;
