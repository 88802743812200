import * as React from 'react';
import { PersonalAgreementQuestionProps as Props } from './ElementTypeInterfaces';
import nl2br from 'nl2br';
import {
  FormControlLabel,
  Checkbox,
  Fab,
  Theme,
  Typography,
  Paper,
  Hidden,
  makeStyles,
} from '@material-ui/core';

import personalAgreementBg from '../static/images/personal-agreement-bg.png';
import personalAgreementBgTriangle from '../static/images/personal-agreement-bg-triangle.png';

const preloadImage = (url) => {
  const img = new Image();
  img.src = url;
};
setTimeout(function () {
  preloadImage(personalAgreementBg);
  preloadImage(personalAgreementBgTriangle);
}, 500);

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    // @ts-ignore
    backgroundImage: `url(${theme.personalAgreement.backgroundImagePath})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    right: 0,
    zIndex: -1,
    [theme.breakpoints.up('md')]: {
      top: 0,
    },
  },
  backgroundTriangle: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: -1,
    width: 300,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  paper: {
    maxWidth: 650,
    padding: theme.spacing(4),
    boxShadow: '13px 10px 10px 0px rgba(0,0,0,0.2)',
  },
  terminateButton: {
    marginRight: theme.spacing(2),
    minWidth: 200,
    backgroundColor: '#ffffff',
    border: 'solid #E1E1E1 2px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#E1E1E1',
    },
  },
  continueButton: {
    minWidth: 200,
    boxShadow: 'none',
    color: '#fff',
    // @ts-ignore
    backgroundColor: theme.personalAgreement.continueButtonColor,
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(),
    },
  },
  continueText: {
    marginBottom: theme.spacing(2),
  },
  agreement: {
    marginBottom: theme.spacing(4),
  },
  agreementLabel: {
    width: '100%',
    textAlign: 'left',
  },
  thanksMessage: {
    color: '#000',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    fontWeight: 200,
  },
  buttons: {
    float: 'right',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column-reverse',
      width: '100%',
    },
  },
  label: {
    fontSize: '0.875rem',
  },
}));

const PersonalAgreementQuestion: React.FunctionComponent<Props> = ({
  agreement,
  continueButtonText,
  continueText,
  onChange,
  terminateButtonText,
  thanksMessage,
  ...otherProps
}) => {
  const [agreementChecked, setAgreementChecked] = React.useState<boolean>(
    false
  );
  const [showError, setShowError] = React.useState<boolean>(false);

  const agreementCheckHandler = () => {
    setAgreementChecked((prev) => !prev);
  };
  const isShowErrorHandler = () => {
    setShowError((prev) => !prev);
  };
  const onContinueClick = () => {
    agreementChecked ? onChange(1) : isShowErrorHandler();
  };

  const onTerminateClick = () => onChange(0);

  const classes = useStyles();
  return (
    <>
      <div className={classes.background} />
      <Hidden smDown>
        <img
          src={personalAgreementBgTriangle}
          alt="triangle"
          className={classes.backgroundTriangle}
        />
      </Hidden>
      <Typography
        className={classes.thanksMessage}
        variant="h5"
        align="center"
        dangerouslySetInnerHTML={{ __html: nl2br(thanksMessage) }}
      />
      <Paper square className={classes.paper}>
        <Typography
          variant="body1"
          className={classes.continueText}
          align="left"
          dangerouslySetInnerHTML={{ __html: nl2br(continueText) }}
        />
        {agreement && (
          <div className={classes.agreement}>
            <div className={classes.agreementLabel}>
              <FormControlLabel
                className={classes.agreementLabel}
                classes={{
                  label: classes.label,
                }}
                control={
                  <Checkbox
                    data-test-id="checkBox"
                    onClick={agreementCheckHandler}
                    value="agreement"
                    checked={agreementChecked}
                  />
                }
                label={agreement.text}
              />
            </div>
            {showError && (
              <Typography
                style={{ fontSize: '0.875rem' }}
                align="center"
                color="error">
                {agreement.errorMessage}
              </Typography>
            )}
          </div>
        )}
        <div className={classes.buttons}>
          <Fab
            data-test-id="terminateButton"
            variant="extended"
            onClick={onTerminateClick}
            size="large"
            color="default"
            className={classes.terminateButton}>
            <Typography variant="caption" color="inherit">
              {terminateButtonText}
            </Typography>
          </Fab>
          <Fab
            data-test-id="continueButton"
            variant="extended"
            onClick={onContinueClick}
            size="large"
            color="secondary"
            className={classes.continueButton}>
            <Typography variant="caption" color="inherit">
              {continueButtonText}
            </Typography>
          </Fab>
        </div>
      </Paper>
    </>
  );
};

export default PersonalAgreementQuestion;
